<template>
    <div>
      <Home_navbar HeaderClass="fixed-top"></Home_navbar>
      <!-- Breadcrumbs section starts here  -->
      <section class="breadcrumbs">
        <div class="container">   
          <div class="d-flex justify-content-between align-items-center">
             <h2>Art Forms | Dance</h2>
            <ol>
              <li><router-link to="/">Home</router-link></li>
              <li>Art Forms</li>
              <li>Dance</li>
            </ol>
          </div>  
        </div>
      </section>

      <!-- ======= Article Section Main & Sidebar ======= -->
      <section class="blog">
        <div class="container">
          <div class="row">
            <!-- The main article section starte here -->
            <div class="col-lg-8">
                <!-- Portfolio Search Box & Add Button-->
                <div>
                  <b-row>
                    <b-col sm="12"><b-form-input v-model="SearchBy" placeholder="Search by art forms"></b-form-input></b-col>
                    <!-- <b-col sm="4"><b-button>Submit Your Photos</b-button></b-col> -->
                  </b-row>    
                </div>
                <!-- Portfolio card Listing goes here -->
                <div v-for="Data in SearchList" v-bind:key="Data.id">
                  <b-row class="row no-gutters profile-card" >
                    <div class="col-md-4 Thira profile-card-5">
                      <div class="card">
                        <div class="card-img-block">
                          <router-link :to="{name:'Art-forms-detail', params:{ SeqNo:Data.seq_no}}"><img class="card-img-top" v-bind:src="Data.title_image" alt="Image"></router-link>
                        </div>
                      </div>
                    </div>
                      <div class="col-md-8 Thira profile-card-5">
                        <div class="card-body event-desc-block">
                          <a class="card-title"><router-link :to="{name:'Art-forms-detail', params:{ SeqNo:Data.seq_no}}"><h5>{{Data.content_title}}</h5></router-link></a>
                          <p>{{Data.main_catg+' | '+Data.sub_catg}}</p>
                          <!-- <p v-if="prof.egall_desc.length<100"><span v-html="prof.egall_desc"></span>
                          <p v-else><span v-html="prof.egall_desc.substring(0,270)"></span></p> -->
                          <p class="card-text pre-formatted desc" v-if="Data.content_desc.length<50"><span v-html="Data.content_desc"></span>
                          <p v-else><span v-html="Data.content_desc.substring(0,130)"></span></p>                        
                          <div class="read-more">
                            <router-link :to="{name:'Art-forms-detail', params:{ SeqNo:Data.seq_no}}">Read more</router-link>
                          </div>  
                        </div> 
                      </div>
                  </b-row>    
                </div>                
            </div>
            <!-- Article Right side bar section starts here -->
            <div class="col-lg-4">
              <div class="sidebar">
                <!-- sidebar search box -->
                <!-- <h3 class="sidebar-title">Search</h3>
                <div class="sidebar-item search-form">
                  <form action="">
                    <input type="text">
                    <button type="submit"><i class="icofont-search"></i></button>
                  </form>
                </div> -->
                <!-- sidebar Categories -->
                <h3 class="sidebar-title">Categories</h3>
                <div class="sidebar-item categories" >
                  <ul v-for="Cnts in RespArrayCnts" v-bind:key="Cnts.id">
                    <li><router-link :to="{name:'ArtForms', params:{ MainCatg: Cnts.main_catg}}">{{Cnts.main_catg}}<span>({{Cnts.rec_count}})</span></router-link></li>
                    <!-- <li>{{Cnts.main_catg}} ( {{ReadSelectedArtforms(Cnts.main_catg)}})<span>{{Cnts.rec_count}}</span></li>                     -->
                    <!-- <li><router-link :to="{name:'ArtForms', params:{ MainCatg:'Dance Drama'}}">Dance Drama<span>(25)</span></router-link></li>
                    <li><router-link :to="{name:'ArtForms', params:{ MainCatg:'Folk Arts'}}">Folk Arts<span>(25)</span></router-link></li>
                    <li><router-link :to="{name:'ArtForms', params:{ MainCatg:'Instruments'}}">Instruments<span>(25)</span></router-link></li>
                    <li><router-link :to="{name:'ArtForms', params:{ MainCatg:'Music'}}">Music<span>(25)</span></router-link></li>
                    <li><router-link :to="{name:'ArtForms', params:{ MainCatg:'Performing Arts'}}">Performing Arts<span>(25)</span></router-link></li>
                    <li><router-link :to="{name:'ArtForms', params:{ MainCatg:'Ritual Arts'}}">Ritual Arts<span>(25)</span></router-link></li> -->
                  </ul>
                </div> 
                <!-- sidebar recent posts-->
                <SideRecentPerformers/>
                <!-- sidebar Filter By Performers Category-->
                <SideFilterPerformers/>  
              </div>

            </div>
            <!-- Article Right side bar Ends here -->
          </div><!-- End row -->
        </div><!-- End container -->
      </section><!-- End Blog Section -->        

      <!-- ======= Inject Footer section here  ======= -->
      <Home_footer/>
    
    </div><!-- End Main Div  -->
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'
import { CardPlugin } from 'bootstrap-vue'

Vue.use(CardPlugin)
Vue.use(Vueaxios,axios)

  export default {
  name:"PerformingArtforms",
  metaInfo: {
    title: "Thiraseela :: ",
    titleTemplate: "%s | Performing Art Forms | Artists Portfolios | Book Stage Shows | Online Program Booking"
  },      
  watch: {
    '$route' (to)
    {
      this.ReadSelectedArtforms(to.params.MainCatg);
    }
  },
    data() {
      return {
        SearchBy: "",
        RespArray: [],
        RespArrayCnts: [],
      }
    },
    mounted()
    {
      // console.log('print',this.$route.params.MainCatg)
      this.ReadCountArtForms();
      if(this.$route.params.MainCatg == 'All') {
        this.ReadAllArtforms()
      } 
      else {
        this.ReadSelectedArtforms(this.$route.params.MainCatg)
      }
    },
    methods: {
      ReadSelectedArtforms(RecToFetch){
      const REQformData = new FormData();
      REQformData.append('main_catg', RecToFetch);        
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadSelectedArtforms', REQformData)
        .then((resp)=>{
        this.RespArray=resp.data.Artforms;
      })
      },
      ReadAllArtforms(){      
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadAllArtforms')
        .then((resp)=>{
        this.RespArray=resp.data.Artforms;
      })
      },      
      ReadCountArtForms(){     
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadCountArtForms')
        .then((resp)=>{
        this.RespArrayCnts=resp.data.ArtFormsCnts;
      })
      },      
    },
    computed: {
    SearchList: function(){
        return this.RespArray.filter((RespArray) => {
        return RespArray.content_title.toLowerCase().match(this.SearchBy.toLowerCase())
      });
    },   
  }
  }
</script>

<style scoped>

.profile-card {
    margin-top:35px;
    box-shadow:0 0 10px rgba(0,0,0,0.63);
    border-radius:5px;
}
.profile-card-5{
    /* box-shadow:0 0 10px rgba(0,0,0,0.63); */
    border-radius:5px;
    height: 220px;
    /* height: 450px; */
    /* height: 475px; */
    /* overflow: hidden;     */
}
.profile-card-5 .card-img-block {
    width: 91%;
    margin: 0 auto;
    position: relative;
    top: -20px;
}
.profile-card-5 .event-desc-block {
  /* border-radius:5px; */
  height: 250px;
  overflow: hidden;

}
.profile-card-5 .card-img-block img{
    border-radius:10px;
    height: 200px;
    overflow: hidden;
}
.profile-card-5 h5{
    color:#e75480;
    font-weight:800;
    font-size:16px;
}
.profile-card-5 p{
    font-size:14px;
    font-weight:300;
}
.pre-formatted {
  white-space: pre-line;
  text-align: justify;
  /* max-height: 450px;
  overflow: auto; */
  display: inline-block;
}
</style>
